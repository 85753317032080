// #TODO: Pass environment variables through config before depending upon them.
import { HOST_API_BASE_URL } from '@/services/connections.service';

const GetParamsValue = (value) => {
  if (value) {
    return value;
  }
  return '';
};

export const getApiUrl = (path, params = {}) => {
  // #TODO: Use JS built-in query params to build the URL-s to avoid edge cases.
  // #TODO: Use Array.reduce to avoid mutating external variables.
  let APIEndPoint = `${HOST_API_BASE_URL}${path}`;
  if (params) {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-return-assign
      (key) =>
        (APIEndPoint = APIEndPoint.replace(
          `:${key}`,
          GetParamsValue(params[key])
        ))
    );
  }
  return APIEndPoint;
};

export const DELETE_CLAIMS_URL = getApiUrl('/claims/delete');

export const GET_CUSTOMER_TESTIMONIALS = getApiUrl('/testimonials');

export const GET_GOOGLE_CUSTOMER_TESTIMONIALS = getApiUrl('/reviews');

export const GET_EVENT_GALLERY = (eventCategoryId) =>
  getApiUrl('/event-gallery?eventCategoryId=:eventCategoryId', {
    eventCategoryId
  });

export const CONTACT_US = getApiUrl('/contact-us');

export const CONTACT_US_USER = getApiUrl('/user-contact-us');

export const GET_WORKING_HOURS = getApiUrl('/working-hours');

export const GET_COMMUNICATION_MODE = getApiUrl('/communication-modes');

export const LEAD_CAPTURE = getApiUrl('/leads');

export const GET_VENUE_TYPES = getApiUrl('/venues');

export const GET_USER_EVENT_CARTS = (userCartId, userEventId) =>
  getApiUrl('/users/:userCartId/carts/list?userEventId=:userEventId', {
    userCartId,
    userEventId
  });

export const GET_CART_DETAILS = ({ userCartId, cartId }) =>
  getApiUrl('/users/:userCartId/carts/:cartId/details', { userCartId, cartId });

export const GET_CART_DETAILS_BY_PLANNER = ({ userCartId, cartId }) =>
  getApiUrl('/ops/users/:userCartId/carts/:cartId/details', {
    cartId,
    userCartId
  });

export const UPDATE_CART_ITEM_SHOW_PRODUCT_MEDIA = ({
  cartId,
  cartItemId,
  userCartId
}) =>
  getApiUrl(
    '/ops/users/:userCartId/carts/:cartId/cart-item/:cartItemId/showProductMedia',
    {
      cartId,
      cartItemId,
      userCartId
    }
  );

export const UPDATE_CART_ITEM_SORT_ORDER = ({ cartId }) =>
  getApiUrl('/ops/cart/:cartId/cart-item/update-sort-order', { cartId });

export const CART_ITEM_OPERATION_URL = ({ userCartId, cartId, cartItemId }) =>
  getApiUrl('/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    userCartId,
    cartId,
    cartItemId
  });

export const CLONE_CART_ITEM = ({ userCartId, cartId, cartItemId }) =>
  getApiUrl(
    '/ops/users/:userCartId/carts/:cartId/cart-item/:cartItemId/clone',
    {
      userCartId,
      cartId,
      cartItemId
    }
  );

export const CLONE_NON_ORDERED_CART = ({ userCartId, cartId }) =>
  getApiUrl('/ops/users/:userCartId/carts/:cartId/clone', {
    userCartId,
    cartId
  });

export const CLONE_ORDERED_CART = ({ userCartId, cartId }) =>
  getApiUrl('/ops/users/:userCartId/carts/:cartId/clone-ordered-cart', {
    userCartId,
    cartId
  });

export const UPDATE_CART_INFO = ({ userCartId, cartId }) =>
  getApiUrl('/users/:userCartId/carts/:cartId', { userCartId, cartId });

export const DELETE_ITEM_FROM_CART_BY_PLANNER = ({
  cartId,
  cartItemId,
  userCartId
}) =>
  getApiUrl('/ops/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    cartId,
    cartItemId,
    userCartId
  });

export const ADD_ITEM_TO_DEFAULT_CART = (userCartId) =>
  getApiUrl('/users/:userCartId/default-cart/cart-item', { userCartId });

export const ADD_ITEM_TO_CART = ({ userCartId, cartId }) =>
  getApiUrl('/users/:userCartId/carts/:cartId/cart-item', {
    cartId,
    userCartId
  });

export const ADD_ITEM_TO_CART_BY_PLANNER = ({ userCartId, cartId }) =>
  getApiUrl('/ops/users/:userCartId/carts/:cartId/cart-item', {
    cartId,
    userCartId
  });

export const AUTHENTICATE_CART = getApiUrl('/authenticate-cart');

export const GET_PRODUCTS = (
  skip,
  limit,
  parentCategory,
  category,
  subCategory,
  sortOrder
) =>
  getApiUrl(
    '/products?skip=:skip&limit=:limit&parentCategory=:parentCategory&category=:category&subCategory=:subCategory&sortOrder=:sortOrder',
    {
      skip,
      limit,
      parentCategory,
      category,
      subCategory,
      sortOrder
    }
  );

export const GET_PRODUCT_KIDS_BDAY = getApiUrl('/products/ready-packages');

export const GET_PRODUCT = (slug) => getApiUrl('/products/:slug', { slug });

export const GET_PRODUCT_BY_ID = (productId) =>
  getApiUrl('/products/:productId/id', { productId });

export const GET_PRODUCT_BREADCRUMBS = (slug) =>
  getApiUrl('/products/:slug/breadcrumbs', { slug });

export const CREATE_GUEST_USER = getApiUrl('/guest-user');

export const CREATE_USER = getApiUrl('/users');

export const USER_LOGIN = getApiUrl('/sessions');

export const RECENT_OTP = getApiUrl('/sessions/resend-otp');

export const GET_USER = (userId) => getApiUrl('/users/:userId', { userId });

export const GET_CATEGORY_TREE = getApiUrl('/category/tree');

export const GET_CATEGORY_SEO_DETAILS = (categorySlug) =>
  getApiUrl('/categories/:categorySlug/seo-details', { categorySlug });

export const GET_USER_ONGOING_ORDERS = (userId, skip, limit) =>
  getApiUrl('/users/:userId/orders?type=ONGOING&skip=:skip&limit=:limit', {
    userId,
    skip,
    limit
  });

export const GET_USER_COMPLETED_ORDERS = (userId, skip, limit) =>
  getApiUrl('/users/:userId/orders?type=COMPLETED&skip=:skip&limit=:limit', {
    userId,
    skip,
    limit
  });

export const GET_USER_ORDER_DETAILS = (userId, orderId) =>
  getApiUrl('/users/:userId/orders/:orderId', { userId, orderId });

export const UPDATE_CART_EXTRA_INFO = getApiUrl(
  '/update-extra-property-in-cart'
);

export const POST_CHECKOUT_EVENT_PRIORITY_ENQUIRY = getApiUrl(
  '/inquiries/priority-event-inquiry'
);

export const GET_CHECKOUT_EVENT_LOOKUP = getApiUrl('/checkout/eventType');

export const EVENT_CART_CHECKOUT = ({ userCartId, cartId }) =>
  getApiUrl('/users/:userCartId/carts/:cartId/checkout-non-default-cart', {
    userCartId,
    cartId
  });

export const EVENT_CART_CHECKOUT_FOR_PLANNER = ({ userCartId, cartId }) =>
  getApiUrl('/ops/users/:userCartId/carts/:cartId/checkout-non-default-cart', {
    userCartId,
    cartId
  });

export const GET_CITY_DETAILS = getApiUrl('/cities');

export const GET_SEARCH_SUGGESTIONS = (text) =>
  getApiUrl('/search-suggestions?text=:text', { text });

export const APPLY_PROMO_CODE = getApiUrl('/apply-promo-code');

export const REMOVE_PROMO_CODE = getApiUrl('/remove-promo-code');

export const UPDATE_USER_DETAILS = (userId) =>
  getApiUrl('/users/:userId/user-details', { userId });

export const UPDATE_USER_ADDRESS = (userId) =>
  getApiUrl('/users/:userId/address', { userId });

export const POST_RFQ_QUOTE_DETAILS = getApiUrl('/rfq-submission-details');

export const UPDATE_USER_TIMEZONE = (userId) =>
  getApiUrl('/users/:userId/user-time-zone', { userId });

export const GET_PUBLISHED_COLLECTIONS = (skip, limit) =>
  getApiUrl('/published-collections?skip=:skip&limit=:limit', { skip, limit });

export const GET_COLLECTION_BY_SLUG = (slug) =>
  getApiUrl('/collections/:slug', { slug });

export const BOOK_TICKET_DETAILS = getApiUrl('/inquiry/booking');

export const POST_VENDOR_REGISTRATION_ENQUIRY = getApiUrl(
  '/inquiries/vendor-registration'
);

export const GET_HAFLA_BLOGS = getApiUrl('/blogs');

export const CREATE_USER_EVENT = (userId) =>
  getApiUrl('/users/:userId/user-events', {
    userId
  });

export const UPDATE_USER_EVENT = (userId, userEventId) =>
  getApiUrl(
    '/users/:userId/user-events/:userEventId/update-user-event-profile',
    {
      userId,
      userEventId
    }
  );

export const GET_PRICE_RANGE_BY_SLUG = getApiUrl('/product-pricerange/');

export const GET_EVENT_SITE_TYPE_LIST = getApiUrl('/event-site-types');

export const GET_CART_QUOTATION = ({ userCartId, cartId }) =>
  getApiUrl('/user/:userCartId/carts/:cartId/quote-pdf', {
    userCartId,
    cartId
  });

export const GET_CARTS_BY_EVENT_ID = ({ userCartId, userEventId }) =>
  getApiUrl('/users/:userCartId/user-events/:userEventId/carts', {
    userCartId,
    userEventId
  });

export const GET_ORDER_MEDIA_BY_EVENT_ID = ({ userId, userEventId }) =>
  getApiUrl('/users/:userId/user-events/:userEventId/orderMedia', {
    userId,
    userEventId
  });

export const ADD_NEW_CART = (userId) =>
  getApiUrl('/ops/users/:userId/cart', { userId });

export const GET_USER_EVENT_DETAILS_BY_ID = ({ userId, userEventId, isCRM }) =>
  getApiUrl(
    `${isCRM ? '/ops' : ''}/users/:userId/user-events/:userEventId/details`,
    {
      userId,
      userEventId
    }
  );

export const GET_USER_ONGOING_EVENTS = (userId, skip, limit) =>
  getApiUrl('/users/:userId/user-events?type=ONGOING&skip=:skip&limit=:limit', {
    userId,
    skip,
    limit
  });

export const GET_USER_PAST_EVENTS = (userId, skip, limit) =>
  getApiUrl('/users/:userId/user-events?type=PAST&skip=:skip&limit=:limit', {
    userId,
    skip,
    limit
  });

export const UPDATE_ARCHIVED_CART_STATUS = (userCartId, cartId) =>
  getApiUrl('/ops/users/:userCartId/carts/:cartId/isArchived', {
    cartId,
    userCartId
  });

export const DEFAULT_CART_CHECKOUT = ({ userCartId, cartId }) =>
  getApiUrl('/users/:userCartId/carts/:cartId/checkout-default-cart', {
    cartId,
    userCartId
  });

export const CREATE_TENDER_AND_DISPATCH_BIDS = () => getApiUrl('/ops/tenders');

export const REVISE_TENDER_AND_DISPATCH_BIDS = ({ referenceId }) =>
  getApiUrl('/ops/tenders/revise/:referenceId', { referenceId });

export const RECONCILE_CART_ITEM_MEDIA_FROM_IMAGEKIT = () =>
  getApiUrl('/ops/cart-item-media/reconcile');

export const DELETE_CART_ITEM_IMAGE = ({
  cartId,
  cartItemId,
  cartItemMediaId
}) =>
  getApiUrl(
    '/ops/cart/:cartId/cart-item/:cartItemId/cart-item-media/:cartItemMediaId',
    { cartId, cartItemId, cartItemMediaId }
  );

export const GET_IMAGEKIT_AUTH = () => getApiUrl('/ops/imagekit/auth');

export const GET_CART_CHECKOUT_INFO = ({ cartId, userCartId }) =>
  getApiUrl('/users/:userCartId/carts/:cartId/checkout', {
    cartId,
    userCartId
  });

export const UPDATE_CART_ITEM_MEDIA_SORT_ORDER = ({ cartId, cartItemId }) =>
  getApiUrl(
    '/ops/cart/:cartId/cart-item/:cartItemId/cart-item-media/update-sort-order',
    { cartId, cartItemId }
  );

export const CHECKOUT_CART_AND_PAY = ({ cartId, userCartId }) =>
  getApiUrl('/users/:userCartId/carts/:cartId/checkout', {
    cartId,
    userCartId
  });

export const GET_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_INFO = ({
  orderId,
  userId
}) =>
  getApiUrl('/users/:userId/orders/:orderId/anybody-pay-due', {
    orderId,
    userId
  });

export const POST_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_URL_REQUEST = ({
  orderId,
  userId
}) =>
  getApiUrl('/users/:userId/orders/:orderId/anybody-pay-due', {
    orderId,
    userId
  });

export const POST_ORDER_DUE_PAYMENT_URL_REQUEST = ({ orderId, userId }) =>
  getApiUrl('/users/:userId/orders/:orderId/pay-due', {
    orderId,
    userId
  });

export const GET_TENDER_RESPONSE = ({ referenceId }) =>
  getApiUrl('/ops/tenders/responses/:referenceId', { referenceId });

export const GET_PAYMENT_STATUS = ({ orderId, paymentId, userCartId }) =>
  getApiUrl('/users/:userCartId/orders/:orderId/payments/:paymentId', {
    orderId,
    paymentId,
    userCartId
  });

export const GET_READ_ONLY_CART_FOR_PUBLIC = ({ cartId }) =>
  getApiUrl('/carts/:cartId/read-only-details', {
    cartId
  });

export const GET_READ_ONLY_CART_FOR_PLANNER = ({ cartId }) =>
  getApiUrl('/ops/carts/:cartId/read-only-details', {
    cartId
  });

export const POST_METABASE_DASHBOARD_SIGNED_URL = () =>
  getApiUrl('/ops/generate-signed-url');

export const GET_ORDER_INVOICE = ({ orderId }) =>
  getApiUrl('/ops/orders/:orderId/invoice', { orderId });

export const GET_LEAD_EXIT_REASONS = () => getApiUrl('/ops/lead-exit-reasons');

export const UPDATE_LEAD_EXIT_REASON = ({ userEventId, userId }) =>
  getApiUrl('/users/:userId/user-event/:userEventId/lead-exit-reason', {
    userId,
    userEventId
  });
