import _ from 'lodash';

import { formatDateToHaflaStandardFormat } from '@/lib/time';
import { getUserEventIdFromSelectedCartCookie } from '@/services/cart.service';
import {
  COOKIE_MAP,
  getCookie,
  removeCookie,
  setCookie
} from '@/services/cookie.service';
import * as HttpService from '@/services/http.service';
import { createTenderAndPublishBids } from '@/services/tender.service';
import {
  ADD_NEW_CART,
  GET_CARTS_BY_EVENT_ID,
  GET_LEAD_EXIT_REASONS,
  GET_ORDER_MEDIA_BY_EVENT_ID,
  GET_USER_EVENT_DETAILS_BY_ID,
  UPDATE_ARCHIVED_CART_STATUS,
  UPDATE_LEAD_EXIT_REASON
} from '@/services/url.service';

export const addNewCart = (userId, data) =>
  HttpService.postWithAuth(ADD_NEW_CART(userId), data);

export const getCartsByEventId = ({ userCartId, userEventId }) =>
  HttpService.getWithAuth(GET_CARTS_BY_EVENT_ID({ userCartId, userEventId }));

export const getOrderMediaByEventId = ({ userId, userEventId }) =>
  HttpService.getWithAuth(GET_ORDER_MEDIA_BY_EVENT_ID({ userId, userEventId }));

export const getUserEventDetailsById = ({ userId, userEventId, isCRM }) =>
  HttpService.getWithAuth(
    GET_USER_EVENT_DETAILS_BY_ID({ userId, userEventId, isCRM })
  );

export const updateArchivedStatus = ({ userCartId, cartId, isArchived }) =>
  HttpService.patchWithAuth(UPDATE_ARCHIVED_CART_STATUS(userCartId, cartId), {
    isArchived
  });

export const getLeadExitReasons = () =>
  HttpService.getWithAuth(GET_LEAD_EXIT_REASONS());

export const updateLeadExitReason = ({
  userEventId,
  userId,
  leadExitReasonId
}) =>
  HttpService.putWithAuth(UPDATE_LEAD_EXIT_REASON({ userEventId, userId }), {
    leadExitReasonId
  });

export const getSelectedUserEvent = () =>
  getCookie({ name: COOKIE_MAP.SELECTED_USER_EVENT });

export const setSelectedUserEvent = ({
  data: userEventId,
  resetPreviousCartSelectionForSameEvent = false
}) => {
  const currentSelectedCart = getCookie({ name: COOKIE_MAP.SELECTED_CART });
  const isCurrentSelectedCartDefaultCart = currentSelectedCart?.isDefault;
  const currentSelectedCartEventId =
    getUserEventIdFromSelectedCartCookie(currentSelectedCart);

  const noUserEventChange = currentSelectedCartEventId === userEventId;

  const eventCookie = setCookie({
    data: userEventId,
    name: COOKIE_MAP.SELECTED_USER_EVENT
  });

  if (noUserEventChange && resetPreviousCartSelectionForSameEvent) {
    return {
      cartCookie: removeCookie({ name: COOKIE_MAP.SELECTED_CART }),
      eventCookie
    };
  }

  if (noUserEventChange || isCurrentSelectedCartDefaultCart) {
    return {
      cartCookie: currentSelectedCart,
      eventCookie
    };
  }

  return {
    cartCookie: removeCookie({ name: COOKIE_MAP.SELECTED_CART }),
    eventCookie
  };
};

export const fetchCartListByEventId = async ({
  setCartCardList,
  userCartId,
  userEventId
}) => {
  const { status: getCartsByEventIdStatus, entity: cartList } =
    await getCartsByEventId({
      userCartId,
      userEventId
    });
  if (getCartsByEventIdStatus) {
    setCartCardList(cartList);
  }
};

export const fetchAddNewCart = async ({
  cartName,
  getUserEventCartList,
  setAddNewCart,
  setCartCardList,
  setLoading,
  setShowToast,
  userEventId,
  userId
}) => {
  setLoading(true);
  setAddNewCart(false);
  const { status, message } = await addNewCart(userId, {
    userEventId,
    cartName
  });
  status && getUserEventCartList();
  fetchCartListByEventId({
    setCartCardList,
    userCartId: userId,
    userEventId
  });
  setShowToast({ show: true, status, message });
  setLoading(false);
};

export const fetchUserEventCartDetails = async ({
  getUserEventCartList,
  isCRM,
  setCartCardList,
  setLoading,
  setUserEvent,
  userCartId,
  userEventId,
  userId
}) => {
  setLoading(true);
  const { status, entity: userEvent } = await getUserEventDetailsById({
    isCRM,
    userEventId,
    userId
  });
  if (!status || _.isEmpty(userEvent)) {
    setLoading(false);
    return;
  }
  setSelectedUserEvent({
    data: userEventId
  });

  await getUserEventCartList();
  setUserEvent(userEvent);
  await fetchCartListByEventId({ userCartId, userEventId, setCartCardList });
  setLoading(false);
};

export const blockScreenCreateTenderAndDispatchBids = async ({
  data,
  firstTender = false,
  getUserEventCartList,
  setCartCardList,
  setLoading,
  setShowToast,
  userEventId
}) => {
  setLoading(true);
  const { status, message } = await createTenderAndPublishBids({
    data,
    firstTender
  });
  if (status) {
    await getUserEventCartList();
    await fetchCartListByEventId({
      setCartCardList,
      userCartId: data.cartId,
      userEventId
    });
  }
  setShowToast({
    message: message ?? message[0],
    show: true,
    status
  });
  setLoading(false);
};

const DUMMY_EVENT_DETAILS = {
  eventContactEmail: 'haflacustomer',
  eventContactName: 'ZD_AUTO_CUST',
  eventDate: '31/12/2099',
  eventStartTime: '24:00',
  formattedAddress: 'Nook Coworking - Dubai - United Arab Emirates'
};

export const isEmptyOrDummyEventDetail = ({ key, value }) => {
  const isGoodValue =
    typeof value === 'string' &&
    !(value.indexOf(DUMMY_EVENT_DETAILS[key]) > -1);
  return !isGoodValue;
};

const countIncompleteEPICGeneratedFields = ({
  userEventDetails: [eventDetail],
  zendeskTicketId
}) => {
  const {
    checkoutEvent: { event: { name: eventName } = {} } = {},
    eventAddress,
    eventContactName,
    eventDate,
    eventStartTime,
    opsUser
  } = eventDetail;
  const formattedAddress =
    typeof eventAddress === 'string'
      ? eventAddress
      : eventAddress?.formattedAddress;

  const requiredEventDetailKeyValueList = [
    { key: 'eventContactName', value: eventContactName },
    {
      key: 'eventDate',
      value: eventDate ? formatDateToHaflaStandardFormat(eventDate) : null
    },
    { key: 'eventName', value: eventName },
    { key: 'eventStartTime', value: eventStartTime },
    { key: 'formattedAddress', value: formattedAddress },
    { key: 'opsUser', value: opsUser?.name },
    { key: 'zendeskTicketId', value: zendeskTicketId }
  ];

  return requiredEventDetailKeyValueList.filter(isEmptyOrDummyEventDetail)
    .length;
};

export const validateMandatoryFieldsForEPICGenerated = (userEvent) =>
  countIncompleteEPICGeneratedFields(userEvent) === 0;

export const getEPICGenrationCompletionPercentage = (userEvent) => {
  const FIELDS_IN_COMPLETED_USEREVENT_PROFILE = 7;
  const incompleteFieldsCount = countIncompleteEPICGeneratedFields(userEvent);
  return parseFloat(
    ((FIELDS_IN_COMPLETED_USEREVENT_PROFILE - incompleteFieldsCount) * 100) /
      FIELDS_IN_COMPLETED_USEREVENT_PROFILE
  ).toFixed(2);
};
