// TODO: more meaningful file name - here we are deserializing the fraction stored as integers in DB

const FRACTIONAL_PRECISION = 100;

const convertToUpperDenomination = (value) => value / FRACTIONAL_PRECISION;

export const convertToLowerDenomination = (value) =>
  Math.round(value * FRACTIONAL_PRECISION);

const getFloatStringToTwoDecimalPlaces = (value) =>
  parseFloat(value).toFixed(2);

const getFloatStringWithZeroFractionTrimmed = (floatString) => {
  const [number, fraction] = floatString.split('.');
  return Math.round(fraction) === 0 ? number : floatString;
};

export const parsePriceValueForAPI = (price) =>
  Math.round(parseFloat(convertToLowerDenomination(price)).toFixed(2));

export const parseFormatPriceValueFromAPI = (value = 0) => {
  const upperDenominationValue = convertToUpperDenomination(value);
  return getFloatStringToTwoDecimalPlaces(upperDenominationValue);
};

export const parseFormatPriceNumberValueFromAPI = (value) =>
  Number(parseFormatPriceValueFromAPI(value));

export const decorateNumberForDisplay = (value) =>
  getFloatStringToTwoDecimalPlaces(value);

export const calculatePercent = (value, total) => (value * 100) / total;

export const calculateMarginPercent = (value, total) =>
  ((total - value) * 100) / total;

export const parseFormatPercentValueFromAPI = (value) =>
  parseFormatPriceValueFromAPI(value);

export const formatDiscount = (value) => convertToUpperDenomination(value);

export const formatDiscountObjectToString = ({ value, type }) => {
  let prefix = '';
  let suffix = '';
  if (type === 'Percentage') {
    prefix = '';
    suffix = ' % off';
  }
  if (type === 'Absolute') {
    prefix = 'Flat ';
    suffix = ' off';
  }
  return `${prefix}${convertToUpperDenomination(value)}${suffix}`;
};

// TODO: Sidd - after host-cart-v1 try to deprecate this function
export const formatToTwoDecimal = (
  value,
  convertValueToUpperDenomination = true,
  trimZeroFraction = true
) => {
  const valueToProcess = convertValueToUpperDenomination
    ? convertToUpperDenomination(value)
    : value;

  const floatStringToTwoDecimalPlaces =
    getFloatStringToTwoDecimalPlaces(valueToProcess);

  return trimZeroFraction
    ? getFloatStringWithZeroFractionTrimmed(floatStringToTwoDecimalPlaces)
    : floatStringToTwoDecimalPlaces;
};
