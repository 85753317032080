export const BID_ITEM_STATUS = {
  ACCEPTED: { label: 'Accepted', value: 'ACCEPTED' },
  PENDING: { label: 'Pending', value: 'PENDING' },
  REJECTED: { label: 'Rejected', value: 'REJECTED' }
};

export const DISPATCH_CTA_STATUS = {
  REQUEST_AGAIN: { label: 'Request Again', value: 'REQUEST_AGAIN' },
  REQUEST_BID: { label: 'Request Bid', value: 'REQUEST_BID' },
  REQUESTING_BID: { label: 'Requesting Bid', value: 'REQUESTING_BID' }
};

export const BID_STATUS = {
  ACTIVE: { label: 'Active', value: 'ACTIVE' },
  EXPIRED: { label: 'Expired', value: 'EXPIRED' }
};

export const BID_SUBMISSION_STATUS = {
  PARTIALLY_SUBMITTED: {
    label: 'Partially Submitted',
    value: 'PARTIALLY_SUBMITTED'
  },
  PENDING: { label: 'Pending', value: 'PENDING' },
  SUBMITTED: { label: 'Submitted', value: 'SUBMITTED' }
};

export const DELIVERY_MODE = {
  DRY_HIRE: { label: 'Dry Hire', value: 'DRY_HIRE' },
  NOT_APPLICABLE: { label: 'Not Applicable', value: 'NOT_APPLICABLE' },
  SELF_DELIVERY: { label: 'Self Delivery', value: 'SELF_DELIVERY' }
};
