import config from '@/config';

import { HOST_PORTAL_URL } from '@/services/connections.service';
import { COOKIE_MAP, getCookie } from '@/services/cookie.service';
import * as HttpService from '@/services/http.service';
import {
  CREATE_USER,
  GET_USER,
  GET_USER_COMPLETED_ORDERS,
  GET_USER_ONGOING_EVENTS,
  GET_USER_ONGOING_ORDERS,
  GET_USER_ORDER_DETAILS,
  GET_USER_PAST_EVENTS,
  POST_ORDER_DUE_PAYMENT_URL_REQUEST,
  RECENT_OTP,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_TIMEZONE,
  USER_LOGIN
} from '@/services/url.service';

const whatsappNumber = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER;

export const createUser = (userLoginDetails) =>
  HttpService.postWithoutAuth(CREATE_USER, userLoginDetails);

export const userLogin = (userLoginDetails) =>
  HttpService.postWithoutAuth(USER_LOGIN, userLoginDetails);

export const recentLoginOtp = (userLoginDetails) =>
  HttpService.postWithoutAuth(RECENT_OTP, userLoginDetails);

export const getUser = (userId) => HttpService.getWithoutAuth(GET_USER(userId));

export const getUserOngoingOrders = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_ONGOING_ORDERS(userId, skip, limit));

export const getUserCompletedOrders = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_COMPLETED_ORDERS(userId, skip, limit));

export const getUserOrderDetails = (userId, orderId) =>
  HttpService.getWithAuth(GET_USER_ORDER_DETAILS(userId, orderId));

export const updateUserDetails = (userId, userDetails) =>
  HttpService.postWithAuth(UPDATE_USER_DETAILS(userId), userDetails);

export const updateUserAddress = (userId, addressDetails) =>
  HttpService.postWithAuth(UPDATE_USER_ADDRESS(userId), addressDetails);

export const updateUserTimeZone = (userId, timeZone) =>
  HttpService.postWithoutAuth(UPDATE_USER_TIMEZONE(userId), timeZone);

export const getUserOngoingEvents = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_ONGOING_EVENTS(userId, skip, limit));

export const getUserPastEvents = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_PAST_EVENTS(userId, skip, limit));

export const postOrderDuePaymentURLRequest = ({ orderId, userId }) =>
  HttpService.postWithAuth(
    POST_ORDER_DUE_PAYMENT_URL_REQUEST({ orderId, userId })
  );

export const getPurchaseEventInfoForGA = async ({
  userId,
  orderId,
  paymentFlowSource
}) => {
  const { status, entity } = await getUserOrderDetails(userId, orderId);
  if (status && entity?.orderItems) {
    return {
      orderItems: entity.orderItems,
      orderId,
      orderTotal: entity.orderTotal,
      paymentFlowSource
    };
  }
  return false;
};

const getGCLIDFromCookie = () => {
  const cookieValue = getCookie({ name: COOKIE_MAP.GCL });
  return cookieValue?.split('.')?.[2];
};

export const getInitiateWhatsappChatLink = (isMobile = true) => {
  const showFullURL = false;
  const { pathname, searchParams } = new URL(window.location.href);
  const pageURLWithoutQueryParam = `${HOST_PORTAL_URL}${pathname}`;

  const searchString = searchParams.toString();
  const encodedQueryString =
    searchString.length > 0 ? `, ${encodeURIComponent(searchString)}` : '';

  const messagePrefix = config.isProduction
    ? ''
    : `Please Ignore this - this message is from the non production portal. \n\n`;

  const whatsAppBaseURL = isMobile
    ? 'https://api.whatsapp.com/send'
    : 'https://web.whatsapp.com/send';

  const fullURL = showFullURL
    ? `\n\n${pageURLWithoutQueryParam}${encodedQueryString}\n\n`
    : '';

  const gclid = searchParams.get('gclid') ?? getGCLIDFromCookie();
  const gclidSuffix = gclid ? `\n\nReference: ${gclid}` : '';

  const formattedMessage = `${messagePrefix}Hi Hafla! I have a query on - ${pageURLWithoutQueryParam}\n\nPlease get in touch.${fullURL}${gclidSuffix}`;

  return encodeURI(
    `${whatsAppBaseURL}?phone=${whatsappNumber}&text=${formattedMessage}`
  );
};
