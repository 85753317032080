import moment from 'moment';

// TODO: clean function
// dozens of refactorable variants present in entire codebase associated to date and time
export const createDateWithTime = (date, time) => {
  const [hours, minutes] = time.split(':');
  if (date) {
    date.setHours(hours.trim());
    date.setMinutes(minutes.trim());
    return date.valueOf();
  }
  return null;
};

export const formatTimeToAmPm = (time) =>
  time && moment(time, 'HH:mm').format('hh:mm A');

export const formatDateToHaflaStandardFormat = (date) =>
  date && moment(date).format('DD/MM/YYYY');

const convertTime24To12HrFormat = (hours, minutes) => {
  if (hours === 0) {
    return [12, minutes, 'AM'];
  }
  if (hours < 12) {
    return [hours, minutes, 'AM'];
  }
  if (hours === 12) {
    return [12, minutes, 'PM'];
  }
  return [hours - 12, minutes, 'PM'];
};

export const getTimeWithMeridiem = (date) => {
  const hours = date.getHours();
  let minutes = date.getMinutes();
  if (`${minutes}`.length === 1) {
    minutes = `0${minutes}`;
  }
  return convertTime24To12HrFormat(hours, minutes);
};

export const getTimeWithMeridiemFromTime = (time) => {
  const [hours, minutes] = time.split(':');
  return convertTime24To12HrFormat(hours, minutes);
};

export const getDateAndTime = (time) => moment.utc(time).format('llll');

export const formatTime = (time) => {
  const hours = Number(time.split(':').slice(0, 1));
  const mins = time.split(':')[1];

  if (hours < 12) {
    const formattedHours =
      Number(hours) < 10 ? `0${Number(hours)}` : Number(hours);
    return `${formattedHours}:${mins} am`;
  }
  if (hours === 12) {
    return `${Number(hours)}:${mins} pm`;
  }
  const formattedHours =
    Number(hours - 12) < 10 ? `0${Number(hours - 12)}` : Number(hours - 12);
  return `${formattedHours}:${mins} ${formattedHours === 12 ? 'am' : 'pm'}`;
};

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUNE',
  'JULY',
  'AUG',
  'SEPT',
  'OCT',
  'NOV',
  'DEC'
];

export const getPdfFormattedDateWithoutTime = (date) =>
  moment(date, true).format('llll').slice(0, 17);

export const getPdfFormattedDateWithTime = (date) =>
  moment(date).format('llll');

export const convertToDateFormat = (date, format) =>
  moment.utc(date).format(format); // TODO: check if we should use utc here

export const convertToStandardDateFormat = (date) =>
  convertToDateFormat(date, 'Do MMMM YYYY');

export const convertToShortMonthDateFormat = (date) =>
  convertToDateFormat(date, 'Do MMM YYYY');

export const HHMMSSToSeconds = (timeStr) => {
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const calculateTotalTimeDifferenceInSeconds = (
  dateString,
  timeString
) => {
  const dateTimeString = `${dateString.substring(0, 10)}T${timeString}`;

  const targetDateTime = moment.utc(dateTimeString);
  const currentDateTime = moment.utc();

  const timeDifferenceInSeconds = targetDateTime.diff(
    currentDateTime,
    'seconds'
  );

  return timeDifferenceInSeconds;
};

export const convertToDateTimeString = ({ date, time }) => {
  const dateStr = date ? convertToShortMonthDateFormat(date) : '';
  const timeStr = time ? formatTimeToAmPm(time) : '';
  return [dateStr, timeStr].filter((str) => str).join(', ');
};

export const isValidDate = (date) => moment(date).isValid();

export const getFormattedDate = ({ date, format }) =>
  moment(date).format(format);

export const subtractDaysFromDate = ({ date, daysToSubtract }) =>
  date && moment(date).subtract(daysToSubtract, 'days');

export const parseDateWithMoment = (date) => moment(date);

export const currentDate = moment();

export const getDurationBetweenDates = ({ date, referenceDate }) =>
  moment.duration(date.diff(referenceDate));
