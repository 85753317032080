const PORTAL_NAME = {
  HOST_PORTAL: 'HOST_PORTAL',
  OPS_PORTAL: 'OPS_PORTAL',
  PLANNER_PORTAL: 'PLANNER_PORTAL'
};

const PORTAL_SUB_DOMAIN_PREFIX = {
  HOST_PORTAL: '',
  OPS_PORTAL: 'ops',
  PLANNER_PORTAL: 'planner'
};

const ROOT_DOMAIN = 'hafla.com';

const env = process.env.NEXT_PUBLIC_APP_ENV ?? 'development';
const envDomainPrefix = process.env.NEXT_PUBLIC_ENV_DOMAIN_PREFIX ?? 'local';
const nginxPort = process.env.NEXT_PUBLIC_NGINX_PORT ?? '8080';
const protocol = process.env.NEXT_PUBLIC_PORTAL_SCHEME ?? 'http';

const HOST_API_PATH_PREFIX = '/api';
const OPS_API_PATH_PREFIX = '/api/ops';

const getFQDN = ({
  portalName = PORTAL_NAME.HOST_PORTAL,
  requestWWWSubdomainPrefix = env === 'production'
}) => {
  const domainName = [envDomainPrefix, ROOT_DOMAIN].filter(Boolean).join('.');

  const prefixWWWToSubdomain =
    requestWWWSubdomainPrefix && portalName === PORTAL_NAME.HOST_PORTAL;

  const wwwPrefix = prefixWWWToSubdomain ? 'www' : '';

  const fqdnWithoutWWW = [PORTAL_SUB_DOMAIN_PREFIX[portalName], domainName]
    .filter(Boolean)
    .join('.');

  return [wwwPrefix, fqdnWithoutWWW].filter(Boolean).join('.');
};

const getURLOrigin = ({
  portalName = PORTAL_NAME.HOST_PORTAL,
  requestWWWSubdomainPrefix = env === 'production'
} = {}) => {
  const fqdn = getFQDN({ portalName, requestWWWSubdomainPrefix });
  const authority = [fqdn, nginxPort].filter(Boolean).join(':');
  return `${protocol}://${authority}`;
};

const HOST_PORTAL_FQDN = getFQDN({ portalName: PORTAL_NAME.HOST_PORTAL });

const HOST_API_BASE_URL = `${getURLOrigin()}${HOST_API_PATH_PREFIX}`;
const HOST_PORTAL_URL = getURLOrigin();

const OPS_PORTAL_URL = getURLOrigin({ portalName: PORTAL_NAME.OPS_PORTAL });
const OPS_API_BASE_URL = `${getURLOrigin()}${OPS_API_PATH_PREFIX}`;

const PLANNER_PORTAL_URL = getURLOrigin({
  portalName: PORTAL_NAME.PLANNER_PORTAL
});

module.exports = {
  getURLOrigin,
  HOST_API_BASE_URL,
  HOST_PORTAL_FQDN,
  HOST_PORTAL_URL,
  OPS_API_BASE_URL,
  OPS_API_PATH_PREFIX,
  OPS_PORTAL_URL,
  PLANNER_PORTAL_URL,
  PORTAL_NAME,
  PORTAL_SUB_DOMAIN_PREFIX,
  ROOT_DOMAIN
};
