/* #TODO: Try using the package, config.
  It's not used as the way to make it work with Next is unknown.
*/

const config = {
  aboutWhatsappLink: 'https://wa.link/gts5fr',
  corporateEventLink: 'https://wa.link/vnzyoh',
  debounceTime: 500,
  isProduction: process.env.NEXT_PUBLIC_APP_ENV === 'production',
  haflaEmail: {
    contactUs: 'contactus@hafla.com'
  },
  premiumEventPlanningLink: 'https://wa.link/sj6dcl',
  testEmail: 'test123@test.com',
  vatPercentage: 5,
  whyChooseHaflaLink: 'https://wa.link/q5je8q',
  zendesk: {
    url: {
      agentTicketView: 'https://hafla4229.zendesk.com/agent/tickets/'
    }
  }
};

export default config;
